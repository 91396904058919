import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Feed, getBaseUrl, Note, Potty, Pump, Sleep } from "../api/tracker";
import { makeCrudEndpoints } from "../util/endpoints";

export const babyTrackerApi = createApi({
  reducerPath: "babyTrackerApi",
  refetchOnFocus: true,
  tagTypes: ["potty", "pumping", "feed", "note", "sleep"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${getBaseUrl()}/tracker`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    ...makeCrudEndpoints<Feed>()(builder, "feed", "feeds", "feed"),
    ...makeCrudEndpoints<Pump>()(builder, "pump", "pumps", "pumping"),
    ...makeCrudEndpoints<Sleep>()(builder, "sleep", "sleep", "sleep"),
    ...makeCrudEndpoints<Potty>()(builder, "potty", "potty", "potty"),
    ...makeCrudEndpoints<Note>()(builder, "note", "notes", "note"),
  }),
});
