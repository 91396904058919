import React from "react";
import { Card, CardContent, CircularProgress, Typography } from "@mui/material";
import {
  formatLastBottleFeed,
  formatLastBreastFeed,
} from "../../util/formatting";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { useAppSelector } from "../../data/rootReducer";
import { feedingStatsSelector } from "../../data/selectors/dataSelectors";

export default function FeedingStatsCard() {
  const { isLoading, isError } = babyTrackerApi.useGetAllFeedsQuery();
  const feedingStats = useAppSelector(feedingStatsSelector);

  if (isLoading || !feedingStats) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Failed to get feeding stats</div>;
  }

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Feeding
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatLastBottleFeed(feedingStats.lastBottleFeeding)}
          <br />
          {formatLastBreastFeed(feedingStats.lastBreastFeeding)}
        </Typography>
        <hr />
        <Typography variant="body2" color="text.secondary">
          {feedingStats.last3hours.bottle}mls, {feedingStats.last3hours.breast}m
          on breast - last 3 hours
          <br />
          {feedingStats.last24hours.bottle}mls,{" "}
          {feedingStats.last24hours.breast}m on breast - last 24 hours
        </Typography>
      </CardContent>
    </Card>
  );
}
