import { IconButton } from "@mui/material";
import React from "react";
import { IoRefresh } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { homeAutomationApi } from "../../data/homeAutomationApi";
import { useLongPressEvent } from "../../hooks/domEvents";

export default function AppRefreshButton() {
  const dispatch = useDispatch();
  const refreshHandler = useLongPressEvent(
    () => {
      dispatch(babyTrackerApi.util.resetApiState());
      dispatch(homeAutomationApi.util.resetApiState());
    },
    () => window.location.reload()
  );

  return (
    <IconButton
      onMouseDown={refreshHandler.mouseDown}
      onMouseUp={refreshHandler.mouseUp}
      onTouchStart={refreshHandler.mouseDown}
      onTouchEnd={refreshHandler.mouseUp}
      color="inherit"
    >
      <IoRefresh />
    </IconButton>
  );
}
