import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";
import { resetAllData } from "../../api/tracker";

export default function DangerZone() {
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Danger zone
        </Typography>
        <Stack spacing={1} direction="column">
          <Button variant="contained" color="warning" onClick={resetAllData}>
            Reset all data
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
