import { useEffect, useMemo } from "react";

export function useOnWindowFocus(cb) {
  useEffect(() => {
    window.addEventListener("pageshow", cb);
    return () => window.removeEventListener("pageshow", cb);
  }, [cb]);
}

export function useLongPressEvent(shortPress, longPress) {
  return useMemo(() => {
    let timeout;
    const mouseUp = (evt) => {
      evt.returnValue = false;
      evt.preventDefault();
      clearTimeout(timeout);
      shortPress();
    };
    const mouseDown = (evt) => {
      evt.returnValue = false;
      evt.preventDefault();
      timeout = setTimeout(() => {
        longPress();
      }, 1000);
    };
    return {
      mouseUp,
      mouseDown,
    };
  }, [shortPress, longPress]);
}
