import { TimePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { Dayjs } from "dayjs";
import React from "react";

interface TimeFieldProps {
  label: string;
  value: Dayjs;
  maxTime?: Dayjs;
  onChange: (value: Dayjs) => void;
}

export default function TimeField({
  label,
  value,
  maxTime,
  onChange,
}: TimeFieldProps) {
  return (
    <TimePicker
      maxTime={maxTime}
      value={value}
      label={label}
      onChange={onChange}
      renderInput={(params) => <TextField margin="dense" {...params} />}
    />
  );
}
