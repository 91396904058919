import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const NavButtonBase = styled(Button)`
  background-color: rgba(255, 255, 255, 0);

  &.active {
    background-color: rgba(255, 255, 255, 0.25);
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }

  flex-direction: column;
  align-items: center;

  font-size: 12px;
  margin: 0;
  line-height: unset;
  min-width: 56px;
  text-transform: unset;
  flex-grow: 1;

  .MuiButton-startIcon {
    display: block;
    margin: 0;
    margin-top: 4px;
    margin-bottom: -2px;
  }
`;

export default function NavButton({ to, ...rest }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isActive = to === pathname || (to !== "/" && pathname.startsWith(to));

  return (
    <NavButtonBase
      color="inherit"
      {...rest}
      variant="contained"
      disableElevation
      disableFocusRipple
      disableTouchRipple
      disableRipple
      className={isActive ? "active" : ""}
      onClick={() => (!isActive ? navigate(to) : "")}
    />
  );
}

NavButton.propTypes = {
  to: PropTypes.string.isRequired,
};
