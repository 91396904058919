import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import React from "react";
import { FaPoop } from "react-icons/fa";
import { IoWater } from "react-icons/io5";
import { getDisplayTime } from "../../util/formatting";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { useDispatch } from "react-redux";
import { dialogSlice } from "../../data/dialogSlice";

export default function PottyCard() {
  const { data, isLoading, isError } = babyTrackerApi.useGetAllPottysQuery();

  const dispatch = useDispatch();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Failed to get potty info</div>;
  }

  const lastPotty = data[0];

  return (
    <Card>
      <CardActionArea
        onClick={() =>
          dispatch(dialogSlice.actions.openPottyDialog({ record: lastPotty }))
        }
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Potty
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {getDisplayTime(lastPotty.date)}{" "}
            {lastPotty.output.map((type) =>
              type === "Poo" ? <FaPoop key={type} /> : <IoWater key={type} />
            )}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
