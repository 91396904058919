import React, { useCallback } from "react";
import { dialogSlice } from "../../data/dialogSlice";
import { useAppSelector, useThunkDispatch } from "../../data/rootReducer";
import FeedDialog from "./FeedDialog";
import NoteDialog from "./NoteDialog";
import PottyEditDialog from "./PottyEditDialog";

export default function DialogContainer() {
  const currentDialog = useAppSelector((state) => state.dialog.openDialog);
  const dialogArgs = useAppSelector((state) => state.dialog.dialogArgs);
  const dispatch = useThunkDispatch();

  const onClose = useCallback(() => {
    dispatch(dialogSlice.actions.closeDialog());
  }, [dispatch]);

  switch (currentDialog) {
    case "note":
      return <NoteDialog {...dialogArgs} onClose={onClose} />;
    case "feed":
      return <FeedDialog {...dialogArgs} onClose={onClose} />;
    case "potty":
      return <PottyEditDialog {...dialogArgs} onClose={onClose} />;
    default:
      return null;
  }
}
