import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const siteApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
  }),
  refetchOnFocus: true,
  reducerPath: "siteApi",
  endpoints: (builder) => ({
    getVersion: builder.query<{ version: string }, void>({
      query: () => "version-info.txt",
    }),
  }),
});
