import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

interface PlayerState {
  state: "playing" | "paused" | "transitioning" | "stopped";
}

export const homeAutomationApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "https://pi.schwind.house",
  }),
  reducerPath: "homeAutomationApi",
  tagTypes: ["play-state"],
  endpoints: (builder) => ({
    getPlayerState: builder.query<PlayerState, void>({
      query: () => ({
        url: "/api/sonos/play-state",
      }),
      providesTags: ["play-state"],
    }),
    pausePlayer: builder.mutation<PlayerState, void>({
      query: () => ({
        url: "/api/sonos/pause",
        method: "PUT",
      }),
      invalidatesTags: ["play-state"],
    }),
    playWhiteNoise: builder.mutation<PlayerState, void>({
      query: () => ({
        url: "/api/sonos/play-white-noise",
        method: "PUT",
      }),
      invalidatesTags: ["play-state"],
    }),
  }),
});
