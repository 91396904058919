export interface BaseRecord {
  id: number;
  created: string;
  date: string;
  notes: string;
}

export interface Feed extends BaseRecord {
  minutesOnBreast?: null | number;
  mlsFromBottle?: null | number;
}

export interface Note extends BaseRecord {
  tags: string[];
  imageId?: null | number;
}

export interface Potty extends BaseRecord {
  output: string[];
}

export interface Pump extends BaseRecord {
  pumpedMls: number;
}

export interface Sleep extends BaseRecord {
  durationMinutes: number;
}

export interface UploadedFile {
  id: number;
  created: string;
  mimetype: string;
}

export type CreateRecord<T> = Omit<T, "id" | "created">;

export const getBaseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:8888/api";
  }

  return "/api";
};

const apiKey = window.localStorage.getItem("apiKey");
export const hasApiKey = () => !!apiKey;

export const authWithAirtable = () =>
  fetch(`${getBaseUrl()}/auth/airtable`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    body: JSON.stringify({ key: apiKey }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const resetData = () =>
  fetch(`${getBaseUrl()}/tracker/reset`, {
    method: "POST",
    credentials: "include",
  });

const migrateData = () =>
  fetch(`${getBaseUrl()}/migrate/airtable?key=${apiKey}&db=appkM4esd3oTR3Bcc`, {
    method: "POST",
    credentials: "include",
  });

export const resetAllData = () =>
  resetData()
    .then(migrateData)
    .then(() => window.location.reload());

export const uploadFile = async (file: File): Promise<UploadedFile> => {
  const formData = new FormData();
  formData.append("file", file, file.name);

  const res = await fetch(`${getBaseUrl()}/files/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
  });
  return res.json();
};
