import { Divider, Grid, List } from "@mui/material";
import React from "react";
import FeedingStatsCard from "../card/FeedingStatsCard";
import PottyCard from "../card/PottyCard";
import PumpCard from "../card/PumpCard";
import FeedingActionList from "../list/FeedingActionList";
import LogActionList from "../list/LogActionList";
import PottyActionList from "../list/PottyActionList";

export default function Home() {
  return (
    <>
      <Grid container spacing={2} padding={1}>
        <Grid item xs minWidth="150px">
          <PottyCard />
        </Grid>
        <Grid item xs minWidth="150px">
          <PumpCard />
        </Grid>
        <Grid item xs minWidth="300px">
          <FeedingStatsCard />
        </Grid>
      </Grid>
      <List disablePadding>
        <LogActionList />
        <Divider />
        <FeedingActionList />
        <Divider />
        <PottyActionList />
      </List>
    </>
  );
}
