import { createSelector } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { BaseRecord, Feed } from "../../api/tracker";
import { babyTrackerApi } from "../babyTrackerApi";
import { AppState } from "../rootReducer";

const allFeedingsQuerySelector = (state: Pick<AppState, "babyTrackerApi">) =>
  babyTrackerApi.endpoints.getAllFeeds.select()(state);

const allPumpsQuerySelector = (state: Pick<AppState, "babyTrackerApi">) =>
  babyTrackerApi.endpoints.getAllPumps.select()(state);

export const allFeedingsSelector = createSelector(
  allFeedingsQuerySelector,
  ({ data }) => data || []
);

export const allPumpsSelector = createSelector(
  allPumpsQuerySelector,
  ({ data }) => data || []
);

const getIntegerOrZero = (val: any) => (Number.isInteger(val) ? val : 0);
const getRecordSumReducer =
  <Field extends string>(fieldname: Field) =>
  <T extends Record<string, any>>(prev: number, record: T) =>
    prev + getIntegerOrZero(record[fieldname]);

const sumBottleMl = getRecordSumReducer("mlsFromBottle");
const sumBreastFeedingTime = getRecordSumReducer("minutesOnBreast");
const hasFieldValue =
  (field: string) =>
  <R extends Record<string, any>>(rec: R) =>
    !!rec[field];

const sumBottleAndBreast = (data: Feed[], since: Dayjs, hoursAgo: number) =>
  data.filter(getAfterTimestampFilter(since, hoursAgo)).reduce(
    (prev, rec) => ({
      bottle: sumBottleMl(prev.bottle, rec),
      breast: sumBreastFeedingTime(prev.breast, rec),
    }),
    { bottle: 0, breast: 0 }
  );

const getAfterTimestampFilter = (from: Dayjs, hoursAgo = 0) => {
  const sinceTime = from.clone().subtract(hoursAgo, "hours");
  return <T extends BaseRecord>(rec: T) => dayjs(rec.date).isAfter(sinceTime);
};

const getFeedingStats = (allRecords: Feed[]) => {
  const now = dayjs();

  const last24hours = sumBottleAndBreast(allRecords, now, 24);
  const last3hours = sumBottleAndBreast(allRecords, now, 3);

  return {
    lastBottleFeeding: allRecords.find(hasFieldValue("mlsFromBottle")),
    lastBreastFeeding: allRecords.find(hasFieldValue("minutesOnBreast")),
    last3hours,
    last24hours,
  };
};

export const bottleFeedsSelector = createSelector(
  allFeedingsSelector,
  (feedings) => feedings.filter((feed) => Number.isInteger(feed.mlsFromBottle))
);

export const feedingStatsSelector = createSelector(
  allFeedingsSelector,
  getFeedingStats
);
