import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import DateAdapter from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Provider } from "react-redux";
import { store } from "./data/rootReducer";
import { authWithAirtable } from "./api/tracker";

const DingSound = new Audio("/sounds/correct.mp3");

navigator.serviceWorker.getRegistrations().then((regs) => {
  for (const reg of regs) {
    reg.unregister();
  }
});

const theme = createTheme();

const renderApp = () => {
  ReactDOM.render(
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            classes={{
              containerRoot: "snack-root",
            }}
            onEntered={() => DingSound.play()}
          >
            <LocalizationProvider dateAdapter={DateAdapter}>
              <App />
            </LocalizationProvider>
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>,
    document.querySelector("#app")
  );
};

authWithAirtable().finally(renderApp);
