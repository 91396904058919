import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { FaPoop } from "react-icons/fa";
import { GiNuclearWaste } from "react-icons/gi";
import { IoWater } from "react-icons/io5";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { useOnErrorNotification } from "../../hooks/notifications";

const pottyItems = [
  {
    icon: <GiNuclearWaste />,
    text: "Poo-pee",
    output: ["Poo", "Pee"],
    successMessage: "Nuke explosion recorded!",
  },
  {
    icon: <IoWater />,
    text: "Pee",
    output: ["Pee"],
    successMessage: "Tinkle recorded!",
  },
  {
    icon: <FaPoop />,
    text: "Poop",
    output: ["Poo"],
    successMessage: "Poop recorded!",
  },
];

export default function PottyActionList() {
  const { enqueueSnackbar } = useSnackbar();
  const [createPotty] = babyTrackerApi.useCreatePottyMutation();
  const onError = useOnErrorNotification();
  return (
    <>
      <ListSubheader>Potty</ListSubheader>
      {pottyItems.map((item) => (
        <ListItem disablePadding key={item.text}>
          <ListItemButton
            onClick={() =>
              createPotty({
                output: item.output,
                date: new Date().toISOString(),
                notes: "",
              }).then(() => {
                enqueueSnackbar(item.successMessage, {
                  variant: "success",
                });
              }, onError)
            }
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );
}
