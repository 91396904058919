import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { GiBabyBottle, GiBabyFace, GiOilPump } from "react-icons/gi";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { useOnErrorNotification } from "../../hooks/notifications";
import { useDispatch } from "react-redux";
import { dialogSlice } from "../../data/dialogSlice";

export default function FeedingActionList() {
  const { enqueueSnackbar } = useSnackbar();
  const [createFeed] = babyTrackerApi.useCreateFeedMutation();
  const [createPump] = babyTrackerApi.useCreatePumpMutation();
  const onError = useOnErrorNotification();
  const dispatch = useDispatch();
  const feedItems = [
    {
      icon: <GiBabyBottle />,
      text: "Bottle feed",
      action: () =>
        dispatch(
          dialogSlice.actions.openFeedDialog({
            title: "How much did Ada drink?",
            inputTitle: "mlsFromBottle",
            valueKey: "mlsFromBottle",
            onSave: ({ value, notes, date }) =>
              createFeed({
                mlsFromBottle: value,
                notes,
                date: date.toISOString(),
              }).then(() => {
                enqueueSnackbar("Bottle recorded", {
                  variant: "success",
                });
              }, onError),
          })
        ),
    },
    {
      icon: <GiBabyFace />,
      text: "Breast feed",
      action: () =>
        dispatch(
          dialogSlice.actions.openFeedDialog({
            title: "How long did Ada breast feed?",
            inputTitle: "Minutes fed",
            valueKey: "minutesOnBreast",
            onSave: ({ value, notes, date }) =>
              createFeed({
                minutesOnBreast: value,
                notes,
                date: date.toISOString(),
              }).then(() => {
                enqueueSnackbar("Breast time recorded", {
                  variant: "success",
                });
              }, onError),
          })
        ),
    },
    {
      icon: <GiOilPump />,
      text: "Pump",
      action: () =>
        dispatch(
          dialogSlice.actions.openPumpDialog({
            title: "How did pumping go?",
            inputTitle: "Volume pumped? (mls)",
            valueKey: "pumpedMls",
            onSave: ({ value, notes, date }) =>
              createPump({
                pumpedMls: value,
                notes,
                date: date.toISOString(),
              }).then(() => {
                enqueueSnackbar("Pumped recorded", {
                  variant: "success",
                });
              }, onError),
          })
        ),
    },
  ];

  return (
    <>
      <ListSubheader>Feeding</ListSubheader>
      {feedItems.map((item) => (
        <ListItem disablePadding key={item.text}>
          <ListItemButton onClick={item.action}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );
}
