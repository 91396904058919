import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { GiBabyBottle, GiBabyFace } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { Feed } from "../../api/tracker";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { dialogSlice } from "../../data/dialogSlice";
import { durationToMinutes, minutesToDuration } from "../../util/duration";
import { getDisplayTime } from "../../util/formatting";

interface FeedProps {
  feed: Feed;
}

interface FeedRowProps extends FeedProps {
  subTitle: string;
  Icon: React.ComponentType<{ style: React.CSSProperties }>;
  onEdit: () => void;
}

function FeedRow({ feed, subTitle, Icon, onEdit }: FeedRowProps) {
  return (
    <Card>
      <CardActionArea onClick={onEdit}>
        <CardContent>
          <Stack direction="row" marginBottom={1} alignItems="center">
            <Typography variant="h6" component="span">
              <Icon style={{ position: "relative", top: "2px" }} />{" "}
              {getDisplayTime(feed.date)}
            </Typography>
            <Typography sx={{ paddingLeft: 1 }}>{subTitle}</Typography>
          </Stack>
          <Typography>{feed.notes}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function BottleFeed({ feed }: FeedProps) {
  const dispatch = useDispatch();
  const [updateFeed] = babyTrackerApi.useUpdateFeedMutation();
  const [deleteFeed] = babyTrackerApi.useDeleteFeedMutation();
  const onEdit = useCallback(() => {
    dispatch(
      dialogSlice.actions.openFeedDialog({
        title: "How much did Ada drink?",
        inputTitle: "mls drank",
        valueKey: "mlsFromBottle",
        onDelete: deleteFeed,
        onSave: ({ value, date, notes }) =>
          updateFeed({
            id: feed.id,
            date: date.toISOString(),
            mlsFromBottle: value,
            notes,
          }),
        record: feed,
      })
    );
  }, [dispatch, feed, deleteFeed, updateFeed]);

  return (
    <FeedRow
      feed={feed}
      onEdit={onEdit}
      subTitle={`${feed.mlsFromBottle}ml`}
      Icon={GiBabyBottle}
    />
  );
}

function BreastFeed({ feed }: FeedProps) {
  const dispatch = useDispatch();
  const [updateFeed] = babyTrackerApi.useUpdateFeedMutation();
  const [deleteFeed] = babyTrackerApi.useDeleteFeedMutation();
  const onEdit = useCallback(() => {
    dispatch(
      dialogSlice.actions.openFeedDialog({
        title: "How long did Ada breast feed?",
        inputTitle: "Minutes fed",
        valueKey: "minutesOnBreast",
        encodeValue: minutesToDuration,
        decodeValue: durationToMinutes,
        onDelete: deleteFeed,
        onSave: ({ value, date, notes }) =>
          updateFeed({
            id: feed.id,
            date: date.toISOString(),
            minutesOnBreast: value,
            notes,
          }),
        record: feed,
      })
    );
  }, [dispatch, feed, deleteFeed, updateFeed]);

  return (
    <FeedRow
      feed={feed}
      onEdit={onEdit}
      subTitle={`${feed.minutesOnBreast}m`}
      Icon={GiBabyFace}
    />
  );
}

function Feed({ feed }: FeedProps) {
  if (feed.mlsFromBottle) {
    return <BottleFeed feed={feed} />;
  }

  if (feed.minutesOnBreast) {
    return <BreastFeed feed={feed} />;
  }

  return null;
}

export default function Feeds() {
  const { isLoading, isError, data } = babyTrackerApi.useGetAllFeedsQuery();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Failed to load feedings</div>;
  }

  return (
    <>
      <Paper variant="elevation" square>
        <Stack direction="row" alignItems="center" paddingX={2} paddingY={1}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Feed records
          </Typography>
        </Stack>
      </Paper>
      <Stack gap={1} padding={1}>
        {data.map((feed) => (
          <Feed feed={feed} key={feed.id} />
        ))}
      </Stack>
    </>
  );
}
