import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { getDisplayTime } from "../../util/formatting";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { useDispatch } from "react-redux";
import { dialogSlice } from "../../data/dialogSlice";

export default function PumpCard() {
  const { isLoading, isError, data } = babyTrackerApi.useGetAllPumpsQuery();
  const [deletePump] = babyTrackerApi.useDeletePumpMutation();
  const [updatePump] = babyTrackerApi.useUpdatePumpMutation();
  const dispatch = useDispatch();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Failed to get last pump</div>;
  }

  const lastPump = data[0];

  return (
    <Card>
      <CardActionArea
        onClick={() =>
          dispatch(
            dialogSlice.actions.openPumpDialog({
              title: "How did pumping go?",
              inputTitle: "Volume pumped? (mls)",
              valueKey: "pumpedMls",
              record: lastPump,
              onDelete: deletePump,
              onSave: ({ date, notes, value }) =>
                updatePump({
                  id: lastPump.id,
                  date: date.toISOString(),
                  notes,
                  pumpedMls: value,
                }),
            })
          )
        }
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Pump
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {getDisplayTime(lastPump.date)} {lastPump.pumpedMls}mls
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
