import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { babyTrackerApi } from "./babyTrackerApi";
import { chartSlice } from "./chartSlice";
import { dialogSlice } from "./dialogSlice";
import { homeAutomationApi } from "./homeAutomationApi";
import { siteApi } from "./siteApi";

export const store = configureStore({
  reducer: {
    [babyTrackerApi.reducerPath]: babyTrackerApi.reducer,
    [homeAutomationApi.reducerPath]: homeAutomationApi.reducer,
    [siteApi.reducerPath]: siteApi.reducer,
    dialog: dialogSlice.reducer,
    chart: chartSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      babyTrackerApi.middleware,
      homeAutomationApi.middleware,
      siteApi.middleware
    ),
});

export type AppState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const useThunkDispatch = () => useDispatch<typeof store.dispatch>();

setupListeners(store.dispatch);
